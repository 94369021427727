import React, { useEffect, useMemo, useRef, useState } from 'react';
import Moment from 'react-moment';
import styled from 'styled-components';

import { SafetyDetailsPane } from './styles';
import { Observation, updateObservation } from '../../../../../api/observations';
import { useBuildingContext } from '../../../../../contexts/buildingContext';
import { useNotifications } from '../../../../../contexts/notificationProvider';
import { Icon } from '../../../../common/Icon';
import { useSafetyContext } from '../../../../../contexts/safetyContext';
import { SEVERITY_COLOR_MAP } from '../../../../../data/safety';
import { Pannellum } from '../../../../third_party/Pannellum';
import { Flex } from '@react-css/flex';
import iconClock from '../../../../../assets/images/icon_clock.svg';
import iconHouse from '../../../../../assets/images/icon_house.svg';
import iconMap from '../../../../../assets/images/icon_map.svg';
import iconMapWhite from '../../../../../assets/images/icon_map_white.svg';
import { MinimapViewer } from '../../../../common/MapViewer';
import { MinimapPopupHeader, MinimapPopupImage } from '../../../image_viewer/styles';
import { useViewerPosition } from '../../../image_viewer/hooks/useViewerPosition';
import iconClose from '../../../../../assets/images/icon_close.svg';
import { ViewerPosition } from '../../../image_viewer/types';


export interface DetailPaneProps {
  observation: Observation;
  initialShowMap?: boolean;
  splitCoordinates?: (coordinates: string) => string[];
  onClose?: () => void;
  viewerPosition: ViewerPosition;
  onChangeViewerPosition: (position: ViewerPosition) => void;
  showApproveButton?: boolean;
  showArchiveButton?: boolean;
  changeSeverityDisabled?: boolean;
}
export const DetailPane = ({
  observation,
  initialShowMap = true,
  splitCoordinates=(coordinates: string) => coordinates.split(','),
  onClose,
  viewerPosition,
  onChangeViewerPosition,
  showApproveButton=false,
  showArchiveButton=false,
  changeSeverityDisabled=false,
}: DetailPaneProps) => {
  const { addNotification } = useNotifications();
  const { state: SafetyState, updateSafety } = useSafetyContext();
  const { state: buildingState } = useBuildingContext();

  const [safe, setSafe] = useState<string>('');
  const [severity, setSeverity] = useState<string>('SAFE');
  const [changeSeverity, setChangeSeverity] = useState<boolean>(false);
  const [showMap, setShowMap] = useState<boolean>(initialShowMap);
  const pannellumRef = useRef<Pannellum | null>(null);

  const goToNext = () => {
    let idx = SafetyState.observations.findIndex(
      (element: Observation) => observation?.id === element.id
    );
    if (idx === SafetyState.observations.length - 1) {
      idx = 0;
    } else {
      idx += 1;
    }
    updateSafety({ activeObservation: SafetyState.observations[idx] || null });
  };

  const updateObservationList = (observation: Observation) => {
    let indx = SafetyState.observations.findIndex(
      (o: Observation) => o.public_id === observation.public_id
    );
    if (indx >= 0) {
      let newList = [...SafetyState.observations];
      if (!observation.archived && !observation.claimed) {
        newList[indx] = observation;
      } else {
        newList.splice(indx, 1);
      }
      updateSafety({ observations: newList });
    }
    console.log('not found in update list');
  };

  const saveObservation = async (updatedSafeValue: string, updatedSeverityValue: string, updatedClaimedValue: boolean) => {
    let data = {
      unsafe: updatedSafeValue === 'Unsafe',
      severity: updatedSeverityValue === 'SAFE' ? 'SEVERITY_LOW' : updatedSeverityValue,
      claimed: updatedClaimedValue,
    };

    try {
      const updateObservationResponse = await updateObservation(buildingState.projectId, observation.id.toString(), data);
      updateObservationList(updateObservationResponse.data);

      if (updatedClaimedValue) {
        goToNext();
      }
    } catch {
      addNotification('Observation not saved', 'error');
    }
  };

  const archiveObservation = () => {
    let data = {
      archived: true,
    };
    updateObservation(buildingState.projectId, observation.id.toString(), data)
      .then(d => {
        updateObservationList(d.data);
      })
      .then(() => goToNext())
      .catch(() => addNotification('Observation not archived', 'error'));
  };

  const onChangeSeverity = async (sev: string) => {
    const updatedSafeValue = sev === 'SAFE' ? 'Safe' : 'Unsafe';
    const updatedSeverity = sev;

    setSeverity(sev);
    setSafe(updatedSafeValue);
    setChangeSeverity(false);

    await saveObservation(updatedSafeValue, updatedSeverity, false);
  }

  useEffect(() => {
    if (observation) {
      if(!observation.unsafe)
        setSeverity('SAFE');
      else
        setSeverity(observation.severity);
      setSafe(observation.unsafe ? 'Unsafe' : 'Safe');
    }
  }, [observation]);

  useEffect(() => {
    if (observation?.labels.length && pannellumRef.current) {
      var imgW:number, imgH: number;
      const img = new Image();
      img.onload = function() {
        //@ts-ignore
        imgW = this.width
        //@ts-ignore
        imgH = this.height
      }
      //@ts-ignore
      img.src = observation.localImage;
      // console.log(observation.localImage)
      //@ts-ignore
      pannellumRef.current?.pannellumRef.on('load', () => {
        if (observation.localImage === pannellumRef.current?.props.image) {
          observation.labels.forEach((label: any) => {
            let split = splitCoordinates(label.coordinates);
            let xy = getCxCy(split)
            xy = convertXYtoYawPitch(xy[0], xy[1], imgW, imgH);
            pannellumRef.current?.addHotSpot(
              xy,
              'SAFETY',
              () => null,
              () => null,
              label.id,
              false,
              { text: observation.type.name }
            );
            onChangeViewerPosition({
              yaw: xy[1],
              angle: 0,
              pitch: xy[0],
              hfov: 120,
            });
          });
        }
      });
    }
  }, [observation?.localImage, pannellumRef.current]);

  const floorPlanPosition = useMemo(() => {
    if (observation && observation.labels && observation.labels.length > 0) {
      return observation.labels[0].floor_plan_position;
    }

    return null;
  }, [observation]);

  useEffect(() => {
    let pnlmMaster = document.getElementById("pnlm-master");
    if(pnlmMaster){
      pnlmMaster.addEventListener('mousewheel', function(e) {
        e.preventDefault()
      })
    }
  }, []);

  if (!observation) return <></>;

  return (
    <SafetyDetailsPane>
      <div id="pnlm-master" style={{ height: '100%', width: '100%', borderRadius: '2px' }}>
        {observation.localImage && !observation.is_manual && (
          <Pannellum
            image={observation.localImage}
            initialPosition={() => ({ yaw: 0, angle: 0, pitch: 0, hfov: 120 })}
            viewerPosition={viewerPosition}
            sync={true}
            angleOffset={observation.image?.processed_image?.viewpoints_image.angle}
            onError={() => null}
            ref={pannellumRef}
            onUpdate={x => {
              onChangeViewerPosition({
                yaw: x.rot,
                hfov: x.fov,
                pitch: x.pitch,
                angle: buildingState.imageData?.data?.angle ?? 0,
              });
            }}
          />
        )}
        {observation.is_manual && (
          <img
            src={observation.localImage}
            height="300px"
            width="100%"
            style={{ objectFit: 'contain' }}
            alt="safety_image"
          />
        )}
      </div>

      <Flex
        style={{
          padding: '10px',
          position: 'absolute',
          top: 0,
          width: '100%',
          justifyContent: 'end',
          alignItems: 'center'
        }}
      >
        <Flex
          style={{
            padding: `10px`,
            position: 'absolute',
            top: 0,
            width: showMap ? '300px' : 'fit-content',
            height: showMap ? '300px' : 'fit-content',
            flexDirection: "column"
          }}
        >
          <MinimapPopupHeader transparent={!showMap}>
            <Flex style={{cursor: 'pointer'}} justifyEnd>
              <Icon size={18} icon={showMap ? iconMap : iconMapWhite} onClick={() => setShowMap(!showMap)}/>
            </Flex>
          </MinimapPopupHeader>
          {showMap &&
            <MinimapPopupImage>
              <MinimapViewer
                point={observation.image?.processed_image?.viewpoints_image.point}
                angleOffset={observation.image?.processed_image?.viewpoints_image.angle}
                fov={viewerPosition.hfov}
                image={observation.project_floor.latest_floor_plan.web_image_url}
                rotation={viewerPosition.yaw}
                selectedDateRange={buildingState.dateRange}
                boundingBox={floorPlanPosition}
              />
            </MinimapPopupImage>
          }
        </Flex>
        {onClose &&
          <CloseButtonContainer
            onClick={onClose}
          >
            <img
              style={{
                width: '17px',
                height: '17px',
              }}
              src={iconClose}
              alt=""
            />
          </CloseButtonContainer>
        }
      </Flex>
      <Flex style={{ padding: '10px', position: 'absolute', width: '100%', bottom: 0, alignItems: 'end'}}>
      <div style={{width: '100%'}}>
        <DetailRow style={{ marginTop: '0', display: 'flex', justifyContent: 'space-between' }}>
          <Flex justifyEnd flexDirection="column">
            <div style={{margin: '5px 0'}}>
              {changeSeverity && Object.keys(SEVERITY_COLOR_MAP).map((sev: string) => sev !== severity &&(
                <SafetyTag
                  severity={sev}
                  style={{margin: "10px 0"}}
                  onClick={() => onChangeSeverity(sev)}
                >
                  <p>{sev === 'SAFE' ? 'SAFE' : sev.split("_")[1]}</p>
                </SafetyTag>
              ))}
              <SafetyTag
                disabled={changeSeverityDisabled}
                severity={safe === 'Safe' ? 'SAFE' : severity}
                onClick={ () => setChangeSeverity(!changeSeverity)}
              >
                <p>{safe === 'Safe' ? 'SAFE' : severity.split("_")[1]}</p>
              </SafetyTag>
            </div>
            <Flex style={{ marginBottom: '7px' }}>
              <h1 style={{ fontSize: '1.4em', color: '#fff' }}>{observation?.type.name}</h1>
            </Flex>
          </Flex>
        </DetailRow>
        <DetailRow>
          <InfoTile>
            <Icon icon={iconHouse} size={10} style={{ marginRight: '5px' }} />
            <p className="value">
              {observation.is_manual
                ? observation.project_floor.name
                : observation?.image.processed_image?.viewpoints_image.project_floor}
            </p>
          </InfoTile>
          <InfoTile>
            <Icon icon={iconClock} size={10} style={{ marginRight: '5px' }} />
            <span className="value">
              <Moment date={observation?.image.processed_image?.viewpoints_image.taken_on} format="MMM Do, YYYY" />
            </span>
          </InfoTile>
        </DetailRow>
      </div>
      {(showApproveButton || showArchiveButton) && (
        <Flex style={{width: '100%', justifyContent: 'end'}}>
          {showArchiveButton &&
            <>
              {!observation.claimed && !observation.archived && (
                <DetailsButton onClick={archiveObservation}>
                  <h2>Archive</h2>
                </DetailsButton>
              )}
              {observation.archived && (
                <DetailsButton className={'disabled'}>
                  <h2>Archived</h2>
                </DetailsButton>
              )}
            </>
          }
          {/* {showApproveButton &&
            <>
              {!observation.archived && !observation.claimed && (
                <DetailsButton onClick={saveObservation} className="primary">
                  <h2>Approve</h2>
                </DetailsButton>
              )}
              {observation.claimed && !observation.archived && (
                <DetailsButton className={'disabled'}>
                  <h2>Approved</h2>
                </DetailsButton>
              )}
            </>
          } */}
        </Flex>
        )}
      </Flex>
    </SafetyDetailsPane>
  );
};

function convertXYtoYawPitch(x: number, y: number, imgW: number, imgH: number) {
  let xPercentage = x / imgW;
  let yPercentange = y / imgH;
  let yaw = xPercentage * 360;
  let pitch = (yPercentange - 0.5) * -180;
  return [pitch, yaw - 180];
}

function getCxCy(coordinates: string[]){
  let numbers = coordinates.map(Number)
  return [numbers[0]+(numbers[2]/2), numbers[1]+(numbers[3]/3)]
}

function hexToRgbA(hex: string, opacity=1){
  var c;
  if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
    c= hex.substring(1).split('');
    if(c.length== 3){
      c= [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c= '0x'+c.join('');
    // @ts-ignore
    return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+','+opacity+')';
  }
  throw new Error('Bad Hex');
}

const DetailRow = styled.div``;

export const DetailsButton = styled.div`
  margin: 5px;
  border-radius: 2px;
  outline: 1px solid rgba(255, 255, 255, 0.3);
  color: #fff;
  cursor: pointer;
  padding: 10px 10px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 125px;

  &.primary {
    background: rgba(255, 255, 255, 0.3);
    color: #fff;
  }

  &.disabled {
    cursor: default;
    color: #ccc;
    border: 1px solid #ccc;
  }
`;

const InfoTile = styled.div`
  background: rgba(255, 255, 255, 0.3);
  padding: 3px 5px;
  display: flex;
  align-items: center;
  width: fit-content;
  border-radius: 5px;
  margin: 5px 0;

  .value {
    color: #ffffff;
    font-size: 0.8em;
  }
`;

interface SafetyTagProps {
  severity: string;
  disabled?: boolean;
}

const SafetyTag = styled.div<SafetyTagProps>`
  background: ${(props: SafetyTagProps) => hexToRgbA(SEVERITY_COLOR_MAP[props.severity], 0.7)};
  padding: 4px 10px;
  height: auto;
  width: min-content;
  font-size: 0.75em;
  border-radius: 10px;
  cursor: ${props => props.disabled ? 'default' : 'pointer'};
  pointer-events: ${props => props.disabled ? 'none' : 'auto'};

  p{
    color: #ffffff;
    opacity: 1;
  }
`;

const CloseButtonContainer = styled.div`
  position: absolute;
  top: 8px;
  left: 8px;
  background-color: #00000077;
  padding: 10px;
  box-shadow: 0 1px 20px rgba(0, 0, 0, 0.1);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
