import { QuickFilter } from '../../../../common/QuickFilter/QuickFilter';
import { Flex } from '@react-css/flex';
import { useMemo } from 'react';
import styled from 'styled-components';

export interface IFilterOption {
  text: string;
  field: string | null;
  value: string | boolean | null;
  locked?: boolean;
}

const DEFAULT_FILTER_OPTIONS: IFilterOption[] = [
  { text: 'All', field: null, value: null },
  { text: 'Safe', field: 'unsafe', value: false },
  { text: 'Unsafe', field: 'unsafe', value: true },
  // { text: 'Approved', field: 'claimed', value: true},
  // { text: 'Archived', field: 'archived', value: true},
  // { text: 'Lifts', field: 'category', value: 'Scissor/Aerial Lifts' },
  { text: 'PPE', field: 'category', value: 'PPE' },
  // { text: 'Holes', field: 'type_codes', value: 'FALL_ROTECTION_OPENING_PROTECTED,FALL_ROTECTION_FLR_COVRS' },
  { text: 'Fall Protection', field: 'category', value: 'Fall Protection' },
  // { text: 'Ladders', field: 'category', value: 'Ladders & Stairs' },
  { text: 'Slip', field: 'type_codes', value: 'HOUSEKEEPING_SLIP' },
  { text: 'Rebar', field: 'type_codes', value: 'PPE_REBAR_CAPS' },
];

interface FilterBoxProps {
  activeFilters: IFilterOption[];
  onSelectFilter: (filter: IFilterOption) => void;
  excludeOptions?: string[];
}
export const FilterBox = ({
  activeFilters,
  onSelectFilter,
  excludeOptions = [],
}:FilterBoxProps) => {
  const filterOptions = useMemo(() => {
    const excludeOptionsSet = new Set(excludeOptions);

    return DEFAULT_FILTER_OPTIONS.filter(item => !excludeOptionsSet.has(item.text));
  }, [excludeOptions]);

  const activeFilterTextSet = useMemo(() => {
    const activeFilterTextSet = new Set(activeFilters.map(item => item.text));

    return activeFilterTextSet;
  
  }, [activeFilters]);

  return(
    <FilterBoxContainer>
      {filterOptions.map(item => (
        <QuickFilter
          key={item.text}
          item={item}
          active={activeFilterTextSet.has(item.text)}
          onClick={onSelectFilter}
        />
      ))}
    </FilterBoxContainer>
  )
}

const FilterBoxContainer = styled.div`
  display: flex;
  position: absolute;
  // width: 813px;
  gap: 12px;
  background: #ffffff;
  border-radius: 2px;
  z-index: 100;
  padding: 10px;
  top: 75px;
  left: 0;
  box-shadow: 0 10px 10px #0000005c;
`;



