import React from 'react';
import styled from 'styled-components';

import 'pannellum/src/js/libpannellum';
import 'pannellum/src/js/pannellum';
import 'pannellum/src/css/pannellum.css';

export const pannellum = (window as any).pannellum;

export interface PannellumNative {
  destroy(): void;

  getHfov(): number;

  setHfov(hfov: number): void;

  getYaw(): number;

  getPitch(): number;

  getCanvas(): any;

  setSync(sync: boolean): any;

  removeAllHotspots(): void;
}

const PanoramaRoot = styled.div`
  width: 100%;
  height: 100%;
`;

interface ViewerPosition {
  yaw: number;
  hfov: number;
  pitch: number;
  angle: number;
}

export type PanoramaProps = {
  image: string;
  initialPosition: () => ViewerPosition;
  viewerPosition: ViewerPosition;
  sync: boolean;
  onUpdate?: (data: { rot: number; fov: number; pitch: number }) => void;
  angleOffset: number;
  onError: (err: any) => void;
};

interface PanoramaState {
  currentScene: string | undefined;
}

export class Pannellum extends React.Component<PanoramaProps, PanoramaState> {
  private ref = React.createRef<HTMLDivElement>();
  private pannellumRef: PannellumNative | undefined;

  constructor(props: PanoramaProps) {
    super(props);
    this.state = {
      currentScene: undefined,
    };
  }

  public componentDidMount() {
    let pos = this.props.initialPosition();
    this.pannellumRef = pannellum.viewer(
      this.ref.current,
      {
        default: {
          firstScene: 'home',
          compass: false,
          friction: 0.8,
          mouseZoom: true,
          showZoomCtrl: false,
          showFullscreenCtrl: false,
          autoLoad: true,
          splitScreenSync: this.props.sync,
          disableKeyboardCtrl: true,
        },
        scenes: {
          home: {
            panorama: this.props.image,
            type: 'equirectangular',
            pitch: pos.pitch,
            yaw: pos.yaw,
            hfov: pos.hfov,
          },
        },
      },
      2000
    );

    (this.pannellumRef as any).on('animatefinished', (data: any) => this.broadcastUpdate(data));
    (this.pannellumRef as any).on('error', this.props.onError);
    let el = this.ref.current;
    if (el) {
      el.addEventListener('gesturestart', this.onGesture, true);
      el.addEventListener('gestureend', this.onGesture, true);
      el.addEventListener('gesturechange', this.onGesture, true);
    }
  }

  private onGesture(e: any) {
    e.preventDefault();
  }

  public componentWillUnmount() {
    this.pannellumRef!.destroy();
    let el = this.ref.current;
    if (el) {
      el.removeEventListener('gesturestart', this.onGesture, true);
      el.removeEventListener('gestureend', this.onGesture, true);
      el.removeEventListener('gesturechange', this.onGesture, true);
    }
  }

  public zoomIn() {
    this.pannellumRef!.setHfov(this.getHfov() - 15);
  }

  public zoomOut() {
    this.pannellumRef!.setHfov(this.getHfov() + 15);
  }

  public captureScreenshot(): string {
    const viewer = this.pannellumRef as any;
    return viewer
      .getRenderer()
      .render(
        (viewer.getPitch() / 180) * Math.PI,
        (viewer.getYaw() / 180) * Math.PI,
        (viewer.getHfov() / 180) * Math.PI,
        { returnImage: true }
      );
  }

  componentDidUpdate(prevProps: Readonly<PanoramaProps>, prevState: Readonly<{}>, snapshot?: any) {
    if (prevProps.image !== this.props.image) {
      let pos = this.props.initialPosition();
      (this.pannellumRef as any).addScene('home', {
        panorama: this.props.image,
        type: 'equirectangular',
        pitch: pos.pitch,
        yaw: pos.yaw,
        hfov: pos.hfov,
        splitScreenSync: this.props.sync,
      });
      (this.pannellumRef as any).loadScene('home');
    }

    if (prevProps.viewerPosition !== this.props.viewerPosition && this.props.sync) {
      const viewer = this.pannellumRef as any;
      viewer.lookAt(
        this.props.viewerPosition.pitch,
        this.props.viewerPosition.yaw,
        this.props.viewerPosition.hfov,
        100
      );
    }

    // DEBUG
    // if (prevProps.angleOffset !== this.props.angleOffset) {
    //   (this.pannellumRef as any).addScene('home', {
    //     hotspots: [],
    //     panorama: this.props.image,
    //     type: 'equirectangular',
    //     pitch: this.props.initialPosition?.pitch || 0,
    //     yaw: this.props.initialPosition?.yaw || 0,
    //     hfov: this.props.initialPosition?.hfov || 100,
    //   });
    //   (this.pannellumRef as any).loadScene('home');
    // }
  }

  public getCoordinates(event: any): object {
    const viewer = this.pannellumRef as any;
    return viewer.mouseEventToCoords(event);
  }

  public getConfig() {
    const viewer = this.pannellumRef as any;
    return viewer.getConfig();
  }

  public getContainer() {
    const viewer = this.pannellumRef as any;
    return viewer.getContainer();
  }

  public getPitch() {
    const viewer = this.pannellumRef as any;
    return viewer.getPitch();
  }

  public getYaw() {
    const viewer = this.pannellumRef as any;
    return viewer.getYaw();
  }

  public getHfov() {
    const viewer = this.pannellumRef as any;
    return viewer.getHfov();
  }

  public getCanvas() {
    const viewer = this.pannellumRef as any;
    return viewer.getRenderer().getCanvas();
  }

  public setSync(sync: boolean) {
    const viewer = this.pannellumRef as any;
    return viewer.setSync(sync);
  }

  public getAngleOffset() {
    return this.props.viewerPosition.angle;
  }

  public lookAt(pitch: number, yaw: number) {
    const viewer = this.pannellumRef as any;
    return viewer.lookAt(pitch, yaw);
  }

  public addHotSpot(
    coordinates: any,
    type: string,
    clickHandler: any,
    contextMenuHandler = () => null,
    tagId: number,
    useOffset: boolean,
    extras?: any
  ): void {
    const viewer = this.pannellumRef as any;

    const tagAlreadyPlaced = viewer.getConfig().hotSpots?.find((item: any) => item.id === tagId);
    const tagNeedsUpdated = extras?.tagsToUpdate && extras.tagsToUpdate.find((itemId: number) => itemId === tagId);

    if (tagAlreadyPlaced && !tagNeedsUpdated) {
      return;
    }
    
    if (tagNeedsUpdated) {
      this.removeHotspot(tagId);
    }
    
    return viewer.addHotSpot(
      {
        pitch: coordinates[0],
        yaw: coordinates[1] - (useOffset ? this.props.angleOffset : 0),
        type: type,
        clickHandlerFunc: clickHandler,
        contextMenuHandler: contextMenuHandler,
        contextMenuHandlerArgs: { tagId: tagId },
        id: tagId,
        text: extras?.text || null,
        scale: type === 'TOUR',
        scaleY: extras?.scaleY || 1,
        scaleX: extras?.scaleX || 1,
        cssClass: `oco-ignore pnlm-hotspot pnlm-sprite pnlm-${type} ${
          tagId < 0 ? 'pnlm-tmp' : ''
        } pnlm-id_${tagId} ${extras?.distance ? 'd-' + extras.distance : ''} ${
          extras?.disabled ? 'pnlm-disabled' : ''}`,
        clickHandlerArgs: {
          pitch: coordinates[0],
          yaw: coordinates[1] - (useOffset ? this.props.angleOffset : 0),
          type: type,
          tagId: tagId,
          extras: { ...extras },
        },
      },
      'home'
    );
  }

  public removeHotspot(tagId: any) {
    const viewer = this.pannellumRef as any;
    this.cleanTempHotSpots();
    return viewer.removeHotSpot(tagId, 'home');
  }

  public removeAllHotspots() {
    const viewer = this.pannellumRef as any;
    let hotSpots = viewer.getConfig().hotSpots;
    if (hotSpots?.length) {
      hotSpots.forEach((hs: any) => {
        this.removeHotspot(hs.id);
      });
    }
  }

  private cleanTempHotSpots() {
    const viewer = this.pannellumRef as any;
    let hotSpots = viewer.getConfig().hotSpots;
    hotSpots.forEach((hs: any) => {
      if (hs.div.classList.contains('pnlm-tmp')) {
        viewer.removeHotSpot(hs.id);
      }
    });
  }

  render() {
    return <PanoramaRoot ref={this.ref} />;
  }

  private broadcastUpdate(data: any) {
    if (this.pannellumRef && this.props.onUpdate) {
      this.props.onUpdate({
        rot: data.yaw || 0,
        fov: data.hfov || 0,
        pitch: data.pitch || 0,
      });
    }
  }
}
