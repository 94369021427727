import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { Flex } from '@react-css/flex/dist/src/components/FlexWrapper';

import { MapViewer } from '../../../../common/MapViewer';
import { IconButton } from '../../../../common/IconButton';
import iconZoomInBlue from '../../../../../assets/images/icon_zoom_in_blue.svg';
import iconZoomOutBlue from '../../../../../assets/images/icon_zoom_out_blue.svg';
import iconCrosshairs from '../../../../../assets/images/icon_crosshairs.svg';
import iconCrosshairsWhite from '../../../../../assets/images/icon_crosshairs_white.svg';
import iconCheck from '../../../../../assets/images/icon_check_white.svg';

import { Icon } from '../../../../common/Icon';
import { useBuildingContext } from '../../../../../contexts/buildingContext';
import { LoadingIndicator } from '../../../../common/LoadingIndicator';
import { DateScrollSelector } from '../../components/DateScrollSelector/DateScrollSelector';
import { useQueryParams } from '../../../../../hooks/useQueryParams';
import { LayerManager } from '../LayerManager/LayerManager';
import { ViewSelector } from '../../../../common/ViewSelector/ViewSelector';
import {
  IViewSelectorOption,
  OptionPicker,
} from '../../../../common/ViewSelector/components/OptionPicker';
import { useNavigation } from '../../../../../hooks/useNavigation';
import { useProgressContext } from '../../../../../contexts/progressContext';
import { ProjectTracker } from '../../../../../api/trackers';
import { Job, ProjectJobType } from '../../../../../api/jobs';
import { ProgressCircle } from '../ProgressCircle/ProgressCircle';
import { Point } from '../../../../../api/types';
import { CustomTooltip, CustomTooltipValue } from '../../components/ProgressCharts/CustomTooltip';
import { monthAbbreviations } from '../../ProgressOverview/ProgressOverview';
import { ViewerPosition } from '../../../image_viewer/types';
import { Item } from '../../../../../api/items';
import { ProgressDateSelectorContainer, ProgressDateSelectorTitle } from './styles';

interface TypeSelectorProps {
  name: string;
  displayColor: string;
  checked: boolean;
  onCheck: (checked: boolean) => void;
  onClickLabel: () => void;
  percent: number;
}

export const TypeSelector = ({
  name,
  displayColor,
  checked,
  onCheck,
  onClickLabel,
  percent
}: TypeSelectorProps) => {
  const onClickCheckmark = (e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    onCheck(!checked);
  }

  const handleLabelClick = (e: React.MouseEvent<HTMLHeadingElement> | React.TouchEvent<HTMLHeadingElement>) => {
    e.preventDefault();
    e.stopPropagation();

    onClickLabel();
  }

  return (
    <Flex alignItemsCenter justifySpaceBetween>
      <Flex alignItemsCenter>
        <div
          onClick={onClickCheckmark}
          onTouchEnd={onClickCheckmark}
          style={{
            background: checked ? displayColor : 'transparent',
            border: `1px solid ${displayColor}`,
            padding: '6px',
            borderRadius: '4px',
            marginRight: '5px',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer'
          }}>
          <Icon
            icon={iconCheck}
            size={12}
            style={{
              visibility: checked ? 'visible' : 'hidden',
            }}
          />
        </div>
        <h1
          style={{
            cursor: 'pointer',
          }}
          onClick={handleLabelClick}
        >
          {name}
        </h1>
      </Flex>
      <ProgressCircle progress={percent} color={displayColor} />
    </Flex>
  );
};

interface Stat {
  complete: number;
  total: number;
}

type StatisticalOverview = Record<string, Stat>;

interface IFloorPlanViewProps {
  floorData: any;
  pointData: any;
  dateRange: any;
  viewerPosition: ViewerPosition;
  jobs: Job[];
  jobsLoading: boolean;
}
export const ProgressFloorPlanView = ({ floorData, pointData, dateRange, viewerPosition, jobs, jobsLoading }: IFloorPlanViewProps) => {
  const buildingState = useBuildingContext().state;
  const { state: progressState, updateActiveTracker, updateProgress } = useProgressContext();
  const { navigateToProgressFloor, navigateToProgressFloorPoint } = useNavigation();
  const mapRef = useRef<MapViewer | null>(null);
  const queryParams = useQueryParams();
  const tooltipRef = useRef<HTMLDivElement>(null);
  const [loadedImg, setLoadedImg] = useState('');
  const [floorExpanded, setFloorExpanded] = useState<boolean>(!buildingState.floorId);
  const [trackerExpanded, setTrackerExpanded] = useState<boolean>(false);
  const [jobTypesExpanded, setJobTypesExpanded] = useState<boolean>(true);
  const [selectedTypes, setSelectedTypes] = useState<number[]>([]);
  const [stats, setStats] = useState<StatisticalOverview | null>(null);
  const [tooltipItem, setTooltipItem] = useState<Item | null>(null);
  const [hoverCoordinates, setHoverCoordinates] = useState<{x: number, y: number}>({x: 0, y: 0});

  const {
    trackers,
    activeTracker,
    showPoints,
    observationDate,
    inDebugMode,
  } = progressState;

  const minDate = useMemo(() => {
    let minDate = new Date();

    if (jobs) {
      jobs.forEach((job: Job) => {
        if (job.initial_observation_date) {
          const jobDate = new Date(job.initial_observation_date);

          if (jobDate < minDate) {
            minDate = jobDate;
          }
        }
      });
    } else {
      minDate = new Date(2020, 0, 1);
    }

    return minDate;
  }, [jobs]);

  const activeProjectTracker = useMemo(() => {
    if (trackers) {
      return trackers.find((tracker: ProjectTracker) => tracker.type.name === activeTracker) as ProjectTracker | undefined;
    }
  }, [activeTracker, trackers]);

  const tooltipJobs = useMemo(() => {
    if (tooltipItem) {
      const tooltipItemJobs = jobs.filter(job => job.item.id === tooltipItem.id && job.completed_units > 0 && job.initial_observation_date !== null);
      tooltipItemJobs.sort((a,b) => a.type.job_type.display_order - b.type.job_type.display_order);

      return tooltipItemJobs;
    }

    return [];
  }, [jobs, tooltipItem]);

  const tooltipPosition = useMemo(() => {
    let {x, y} = hoverCoordinates;

    if (tooltipRef.current) {
      const yOffset = 68;
      const xOffset = 30;
      const tooltipHeight = tooltipRef.current.clientHeight;
      const tooltipWidth = tooltipRef.current.clientWidth;

      y = Math.min(y - yOffset - tooltipHeight / 2, window.innerHeight - yOffset - tooltipHeight - 5);

      if (x + xOffset + tooltipWidth + 5 > window.innerWidth) {
        x = x - tooltipWidth - xOffset * 3;
      } else {
        x = x - xOffset;
      }
    }

    return {
      x,
      y,
    }
  }, [hoverCoordinates, tooltipRef.current]);

  const activeTrackerJobTypeIds = useMemo(() => {
    return activeProjectTracker?.types?.map(type => type.job_type.id) ?? [];
  }, [activeProjectTracker]);

  const viewSelectorTrackerJobOptions = useMemo(() => {
    const onCheck = (type: ProjectJobType, checked: boolean) => {
      if (checked) {
        setSelectedTypes(prevSelectedTypes => [...prevSelectedTypes, type.job_type.id]);
      } else {
        setSelectedTypes(prevSelectedTypes => prevSelectedTypes.filter(typeId => type.job_type.id !== typeId));
      }
    }

    const onClickLabel = (jobTypeId: number) => {
      setSelectedTypes([jobTypeId]);
    }

    if (activeProjectTracker && activeProjectTracker.types) {
      const activeProjectTrackerJobTypes =  [...activeProjectTracker.types];
      activeProjectTrackerJobTypes.sort((a,b) => a.job_type.display_order - b.job_type.display_order);

      return activeProjectTrackerJobTypes.map(t => {
        return ({
          Id: t.job_type.id.toString(),
          Content: stats ? (
            <TypeSelector
              name={t.job_type.name}
              displayColor={t.job_type.display_color}
              checked={selectedTypes.includes(t.job_type.id)}
              onCheck={checked => onCheck(t, checked)}
              onClickLabel={() => onClickLabel(t.job_type.id)}
              percent={
                stats[t.job_type.slug]
                  ? Math.round((stats[t.job_type.slug].complete / stats[t.job_type.slug].total) * 100)
                  : 0
              }
            />
          ) : <></>,
        })
      });
    }

    return [];
  }, [activeProjectTracker, selectedTypes, stats]);

  const viewSelectorTrackerOptions: IViewSelectorOption[] = progressState.trackers
    ? progressState.trackers
        .map((tracker: ProjectTracker) => ({
          Id: tracker.id,
          Content: tracker.type.name,
          Options: [],
          OnSelectChild: () => {},
        }))
    : [];

  const viewSelectorFloorOptions: IViewSelectorOption[] = buildingState.projectData.floors
    .map((floor: { name: string; floor_code: string }) => ({
      Id: floor.floor_code,
      Content: floor.name,
      Options: [],
    }))
    .reverse();

  const currentFloor = viewSelectorFloorOptions.filter(
    option => option.Id === buildingState.floorId
  )[0];

  const onSelectFloorOption = (selectedOption: IViewSelectorOption) => {
    navigateToProgressFloor(selectedOption.Id);
  };

  const summarizeJobs = useCallback(() => {
    if (jobs.length) {
      let grouped = groupBy(
        jobs.map((j: any) => ({ ...j, type_id: j.type.job_type.slug })),
        'type_id'
      );
      let overview: StatisticalOverview = {};
      Object.keys(grouped).forEach(group => {
        //@ts-ignore
        overview[group] = {};
        // @ts-ignore
        overview[group].total = grouped[group].reduce((prev: any, curr: any) => {
          return (parseFloat(prev) || 0) + parseFloat(curr.total_units);
        }, 0);
        // @ts-ignore
        overview[group].complete = grouped[group].reduce((prev: any, curr: any) => {
          return (parseFloat(prev) || 0) + parseFloat(curr.completed_units);
        }, 0);
      });
      setStats(overview);
    }
  }, [jobs]);

  useEffect(() => {
    setSelectedTypes(activeTrackerJobTypeIds);
  }, [activeTrackerJobTypeIds]);

  useEffect(() => {
    if (queryParams.get('tracker')) {
      updateActiveTracker(queryParams.get('tracker'));
    }
  }, [queryParams, updateActiveTracker]);

  useEffect(() => {
    if (jobs) {
      summarizeJobs();
    }
  }, [jobs, summarizeJobs]);

  useEffect(() => {
    if (buildingState.pointId) {
      navigateToProgressFloorPoint(buildingState.pointId, observationDate, activeTracker)
    } else {
      navigateToProgressFloor(buildingState.floorId, observationDate, activeTracker)
    }
  }, [
    buildingState.floorId,
    buildingState.pointId,
    navigateToProgressFloor,
    navigateToProgressFloorPoint,
    observationDate,
    activeTracker,
  ]);

  const onClickMapPoint = (point: Point) => {
    navigateToProgressFloorPoint(point.point_id, observationDate);
  }

  const onClickItem = (pointId: string, observationDate?: Date, activeTracker?: string, itemId?: string | number) => {
    if (inDebugMode) {
      navigateToProgressFloorPoint(pointId, observationDate, activeTracker, itemId);
    }
  }

  if (Object.entries(floorData).length === 0) return <></>;

  return (
    <>
      {(loadedImg === floorData.imageUrl && jobsLoading) &&
        <LoadingIndicator
          containerStyle={{width: !!buildingState.pointId ? 'calc(50% + 60px)' : '100%'}}
        />
      }
      <div
        style={{
          position: 'absolute',
          top: '0px',
          bottom: '0px',
          right: '0px',
          left: '0px',
          zIndex: 0,
        }}>
        <MapViewer
          ref={mapRef}
          selectedDateRange={dateRange}
          image={floorData.imageUrl}
          points={floorData.points}
          highlightedPointId={pointData?.point_id}
          showDirectionArrow
          angleOffset={viewerPosition.angle || 0}
          rotation={viewerPosition.yaw}
          setLoadedImg={setLoadedImg}
          imageLoaded={loadedImg === floorData.imageUrl}
          showPoints={showPoints}
          onClickMapPoint={onClickMapPoint}>
          <LayerManager
            jobs={jobs}
            selectedJobTypes={selectedTypes}
            setTooltipItem={setTooltipItem}
            setHoverCoordinates={setHoverCoordinates}
            activeTracker={activeTracker}
            onClickItem={onClickItem}
          />
        </MapViewer>
        <ProgressDateSelectorContainer>
          <ProgressDateSelectorTitle>
            <h1>Progress As Of</h1>
          </ProgressDateSelectorTitle>
          <DateScrollSelector
            justify="center"
            date={observationDate}
            minDate={minDate}
            maxDate={new Date()}
            setDate={d => updateProgress({observationDate: d})}
            showSkipWeekButtons
          />
        </ProgressDateSelectorContainer>
        <FloorSelectorContainer>
          <ViewSelector
            title="Floors"
            initialViewSelectorExpanded
            viewingExpanded={floorExpanded}
            setViewingExpanded={setFloorExpanded}
            maxHeight="calc(100vh - 332px)">
            <OptionPicker
              expanded={floorExpanded}
              setExpanded={setFloorExpanded}
              viewingItems={{
                Id: 'root',
                Content: 'Floor',
                OnSelectChild: onSelectFloorOption,
                Options: viewSelectorFloorOptions,
              }}
              selectedItem={currentFloor}
              hoverItem={null}
              onSelectOption={onSelectFloorOption}
              onHover={() => null}
            />
          </ViewSelector>
        </FloorSelectorContainer>
        <TrackerSelectorContainer>
          <ViewSelector
            title="Trackers"
            initialViewSelectorExpanded
            viewingExpanded={trackerExpanded}
            maxHeight="calc(100vh - 210px)"
            setViewingExpanded={setTrackerExpanded}>
            <OptionPicker
              expanded={trackerExpanded}
              setExpanded={setTrackerExpanded}
              selectedItem={viewSelectorTrackerOptions.find(
                item => item.Content === progressState.activeTracker
              )}
              viewingItems={{
                Id: 'root',
                Content: 'Tracker',
                OnSelectChild: option =>
                  progressState.trackers?.find(
                    (t: ProjectTracker) => t.id.toString() === option.Id
                  ),
                Options: viewSelectorTrackerOptions,
              }}
              onSelectOption={(selection: any) => updateActiveTracker(selection.Content)}
              showBottomBorder
            />
            <OptionPicker
              hideSelectedOptionDisplay
              expanded={jobTypesExpanded}
              setExpanded={setJobTypesExpanded}
              viewingItems={{
                Id: 'root',
                Content: "Job Types",
                Options: viewSelectorTrackerJobOptions,
              }}
            />
          </ViewSelector>
        </TrackerSelectorContainer>
        <IconButton
          icon={showPoints ? iconCrosshairsWhite : iconCrosshairs}
          size={40}
          primary={showPoints}
          onClick={() => updateProgress({showPoints: !showPoints})}
          style={{ position: 'fixed', left: '40px', top: 'calc(90% - 66px)', zIndex: 2 }}
        />
        <IconButton
          icon={iconZoomInBlue}
          size={40}
          primary={false}
          onClick={() => mapRef.current?.zoomIn()}
          style={{ position: 'fixed', left: '40px', top: 'calc(90% - 22px)', zIndex: 2 }}
        />
        <IconButton
          icon={iconZoomOutBlue}
          size={40}
          primary={false}
          onClick={() => mapRef.current?.zoomOut()}
          style={{ position: 'fixed', left: '40px', top: 'calc(90% + 22px)', zIndex: 2 }}
        />
      </div>
      {tooltipItem && (
        <CustomTooltip
          id="tooltip"
          ref={tooltipRef}
          style={{
            position: 'absolute',
            top: `${tooltipPosition.y}px`,
            left: `${tooltipPosition.x}px`,
            padding: '5px',
            width: 'max-content',
          }}
        >
          {tooltipJobs.length === 0 &&
            <CustomTooltipValue
              key="Not Started"
              style={{
                color: 'gray',
              }}
            >
              Not Started
            </CustomTooltipValue>
          }
          {tooltipJobs.map(tooltipJob => {
            let startDatePrintout = 'N/A';

            if (tooltipJob.initial_observation_date) {
              const initialObservationDate = new Date(tooltipJob.initial_observation_date);

              startDatePrintout = `${monthAbbreviations[initialObservationDate.getMonth()]} ${initialObservationDate.getDate()}, ${initialObservationDate.getFullYear()}`;
            }

            return (
              <CustomTooltipValue
                key={tooltipJob.id}
                style={{
                  color: tooltipJob.type.job_type.display_color,
                }}
              >
                {tooltipJob.type.job_type.name} Installed: {startDatePrintout}
              </CustomTooltipValue>
            )
          })}
        </CustomTooltip>
      )}
    </>
  );
};

const groupBy = function (xs: any[], key: string) {
  return xs.reduce(function (rv: any, x: any) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

const FloorSelectorContainer = styled.div`
  position: absolute;
  top: 150px;
  left: 0;
`;

const TrackerSelectorContainer = styled.div`
  position: absolute;
  top: 28px;
  right: 0;
`;
