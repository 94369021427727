import React, { useState } from "react";
import styled from "styled-components";
import { DeleteButton } from "./DeleteButton";
import iconChecked from '../../../../../assets/images/icon_checked.svg';

interface CheckmarkPosition {
  top?: React.CSSProperties['top'];
  right?: React.CSSProperties['right'];
  bottom?: React.CSSProperties['bottom'];
  left?: React.CSSProperties['left'];
}

interface ICheckNameDeleteProps {
  checked?: boolean;
  onChangeChecked?: (newValue: boolean) => void;
  checkboxColor?: string;
  checkboxStyle?: React.CSSProperties;
  checkmarkPosition?: CheckmarkPosition;
  name: string;
  nameStyle?: React.CSSProperties;
  onChangeName?: (newName: string) => void;
  nameInputRef?: React.RefObject<HTMLInputElement>;
  onClickDelete?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  farText?: string | JSX.Element;
  containerStyle?: React.CSSProperties;
}

export const CheckNameDelete = ({
  checked,
  onChangeChecked,
  name,
  nameStyle,
  onChangeName,
  nameInputRef,
  checkboxColor,
  checkboxStyle,
  checkmarkPosition,
  onClickDelete,
  onMouseEnter,
  onMouseLeave,
  farText,
  containerStyle,
}: ICheckNameDeleteProps) => {
  const [nameValue, setNameValue] = useState<string>(name);

  const nameInputOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNameValue(e.target.value);
  }
  
  const nameInputOnPressEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !!onChangeName) {
      onChangeName(nameValue);
    }
  }

  const nameInputOnBlur = () => {
    setNameValue(name);
  }

  return (
    <GroupSelector
      style={containerStyle}
    >
      <CheckboxLabel>
        { !!onChangeChecked &&
          <>
            <CheckboxInput
              checked={checked}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeChecked(e.target.checked)}
            />

            <div>
              <Checkmark
                checked={checked}
                backgroundColor={checkboxColor}
                style={{...checkboxStyle}}
                checkmarkPosition={checkmarkPosition}
              />
            </div>
          </>
        }
        
        { !!onChangeName &&
          <NameInput
            ref={nameInputRef}
            value={nameValue}
            onChange={nameInputOnChange}
            onKeyPress={nameInputOnPressEnter}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onBlur={nameInputOnBlur}
          />
        }

        { !onChangeName &&
          <span
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            style={nameStyle}
          >
            {name}
          </span>
        }
      </CheckboxLabel>

      { !!onClickDelete &&
        <DeleteButton
          onClickDelete={onClickDelete}
        />
      }

      <>{farText}</>
    </GroupSelector>
  )
}

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
`;

const CheckboxInput = styled.input.attrs({ type: 'checkbox' })`
  appearance: none;
`;

const Checkmark = styled.div<{checked?: boolean; backgroundColor?: string; checkmarkPosition?: CheckmarkPosition;}>`
  cursor: pointer;
  display: inline-block;
  margin-right: 10px;
  height: 20px;
  width: 20px;
  border-radius: 4px;
  border: 1px solid ${props => props.checked ? props.backgroundColor ?? '#2196F3' : 'black'};
  background-color: ${props => props.checked ? props.backgroundColor ?? '#2196F3' : 'white'};
  &::after {
    content: url(${iconChecked});
    position: relative;
    ${props => 
      !props.checkmarkPosition && `
        left: 4px;
        top: 2px;
      `
    }
    ${props => `
      ${props.checkmarkPosition?.top ? `top: ${props.checkmarkPosition.top};` : ''}
      ${props.checkmarkPosition?.right ? `right: ${props.checkmarkPosition.right};` : ''}
      ${props.checkmarkPosition?.bottom ? `bottom: ${props.checkmarkPosition.bottom};` : ''}
      ${props.checkmarkPosition?.left ? `left: ${props.checkmarkPosition.left};` : ''}
    `}
  }
`;

const NameInput = styled.input`
  border: none;
  font-size: 14px;
  width: 140px;
`;

export const GroupSelector = styled.div`
  padding: 14px 0;
  position: relative;
  display: flex;
  align-items: center;
  &:not(&:last-child) {
    border-bottom: 1px solid #E2E8F0;
  }
`;