import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import iconClose from '../../../../../assets/images/icon_close.svg';
import { Pannellum } from '../../../../third_party/Pannellum';
import { useBuildingContext } from '../../../../../contexts/buildingContext';
import { getJobsByViewpoint } from '../../../../../api/jobs';
import { ObservationPanel } from './ObservationPanel/ObservationPanel';
import { LoadingIndicator } from '../../../../common/LoadingIndicator';
import { ViewerPosition } from '../../../image_viewer/types';
import { TimeScroll } from './TimeScroll/TimeScroll';

const PreviewPopupBase = styled.div`
  right: -60px;
  top: 0;
  position: absolute;
  width: 50%;
  height: 100%;
  filter: drop-shadow(0px 5px 30px rgba(0, 0, 0, 0.25));
  z-index: 20;
`;

const PreviewPopupIconButton = styled.div`
  position: absolute;
  top: 8px;
  left: 8px;
  background-color: #00000077;
  padding: 10px;
  box-shadow: 0 1px 20px rgba(0, 0, 0, 0.1);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

interface IPointPreviewProps {
  onClose: () => void;
  viewerPosition: ViewerPosition;
  onChangeViewerPosition: (newPosition: ViewerPosition) => void;
  observationDate: Date;
  onChangeObservationDate: (newDate: Date) => void;
  activeTracker?: string;
}

export const PointPreview = (props: IPointPreviewProps) => {
  const { state: buildingState } = useBuildingContext();
  const pannellumRef = useRef<Pannellum | null>(null);
  const [jobs, setJobs] = useState<any[]>([]);
  const [items, setItems] = useState<any[]>([]);
  const [activeItem, setActiveItem] = useState<any>();
  const [obsPanel, setObsPanel] = useState<boolean>(false);

  const activeImage = useMemo(() => {
    return buildingState.imageData.image;
  }, [buildingState.imageData.image]);

  const getJobs = useCallback(() => {
    if (props.activeTracker) {
      getJobsByViewpoint(buildingState.projectId, props.activeTracker, buildingState.pointId).then(d => {
        setJobs(d.data);
        let ii: any = [];
        d.data.map((job: any) => {
          if (!ii.some((item: any) => item.id === job.item.id)) ii.push(job.item);
        });
        setItems(ii);
      });
    }
  }, [buildingState.pointId, buildingState.projectId, props.activeTracker]);

  useEffect(getJobs, [buildingState.pointId, getJobs]);

  return (
    <PreviewPopupBase id="pnlm-master">
      {activeImage ? (
        <Pannellum
          image={buildingState.imageData.image}
          initialPosition={() => ({
            yaw: props.viewerPosition.yaw - (buildingState.imageData.data.angle - props.viewerPosition.angle),
            angle: buildingState.imageData.data.angle,
            pitch: props.viewerPosition.pitch,
            hfov: 120,
          })}
          viewerPosition={props.viewerPosition}
          onUpdate={x => {
            props.onChangeViewerPosition({
              yaw: x.rot,
              hfov: x.fov,
              pitch: x.pitch,
              angle: buildingState.imageData.data.angle,
            });
          }}
          sync={true}
          angleOffset={0}
          onError={() => null}
          ref={pannellumRef}
        />
      ) : (
        <LoadingIndicator/>
      )}
      <PreviewPopupIconButton onClick={props.onClose}>
        <img src={iconClose} alt="" />
      </PreviewPopupIconButton>
      <TimeScroll
        observationDate={props.observationDate}
        onChangeObservationDate={props.onChangeObservationDate}
      />
      {obsPanel && (
        <ObservationPanel
          point={buildingState.pointId}
          setShow={setObsPanel}
          jobs={jobs.filter(j => j.item.id === activeItem)}
          item={items.find(i => i.id === activeItem)}
        />
      )}
    </PreviewPopupBase>
  );
};
