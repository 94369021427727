import React, { useState } from 'react';
import Flex from '@react-css/flex';

import { MinimapPopupHeader, MinimapPopupImage, MinimapPopupDiv } from '../../../styles';
import { Text } from '../../../../../common/Text';
import { FullScreenTimeNaviation } from './FullscreenTimeNavigation';
import { MinimapViewer } from '../../../../../common/MapViewer';
import { IconButton } from '../../../../../common/IconButton';
import iconZoomInBlue from '../../../../../../assets/images/icon_zoom_in_blue.svg';
import iconZoomOutBlue from '../../../../../../assets/images/icon_zoom_out_blue.svg';
import iconLaunch from '../../../../../../assets/images/icon_launch.svg';
import iconLaunchFlip from '../../../../../../assets/images/icon_launch_flip.svg';

import { useBuildingContext } from '../../../../../../contexts/buildingContext';
import { Icon } from '../../../../../common/Icon';
import { useImageViewerContext } from '../../../imageViewerContext';
import { useNavigation } from '../../../../../../hooks/useNavigation';

export const MinimapPopup = ({ mapPopupRef, mapRef, viewerPosition, rightPosition, type }: any) => {
  const {navigateToPointFullscreen} = useNavigation();
  const { state: buildingState } = useBuildingContext();
  const { state: ImageViewerState } = useImageViewerContext();
  const [collapsed, setCollapsed] = useState(false);

  return (
    <MinimapPopupDiv
      ref={mapPopupRef}
      className={'oco-ignore'}
      style={{ right: rightPosition, width: collapsed ? '25px' : '400px' }}>
      <div
        style={{
          cursor: 'pointer',
          borderRadius: '2px',
          padding: '5px',
          backgroundColor: '#fff',
          alignItems: 'center',
          justifyContent: 'center',
          display: collapsed ? 'flex' : 'none',
          opacity: '0.5',
        }}
        onClick={() => setCollapsed(false)}>
        <Icon icon={iconLaunchFlip} size={16} />
      </div>
      <div style={{ display: collapsed ? 'none' : 'block' }}>
        <MinimapPopupHeader>
          <Flex flexDirection="column">
            <Flex flexDirection="row" justifySpaceBetween>
              <Text size={12} marginBottom={10} color="#7E858E">
                {buildingState.projectData.name}, {buildingState.projectData.location.city},{' '}
                {buildingState.projectData.location.state_code}
              </Text>
              <Icon
                icon={iconLaunch}
                size={16}
                onClick={() => setCollapsed(true)}
                style={{ cursor: 'pointer' }}
              />
            </Flex>
            <Flex flexDirection="row" justifyContent={'space-between'}>
              <div>
                <Text size={16} className="minimap-floor-heading">
                  {buildingState.floorData.name}
                </Text>
              </div>
              <FullScreenTimeNaviation point={buildingState.pointData} type={type} />
            </Flex>
          </Flex>
        </MinimapPopupHeader>
        <MinimapPopupImage>
          <MinimapViewer
            ref={mapRef}
            selectedDateRange={null}
            image={buildingState.floorData.imageUrl!}
            point={buildingState.pointData}
            angleOffset={viewerPosition.angle || 0}
            rotation={viewerPosition.yaw}
            fov={viewerPosition.hfov}
            tourPoints={buildingState.floorData.points}
            onClickTourPoint={point => navigateToPointFullscreen(point.point_id, window.location.search)}
          />
          <IconButton
            icon={iconZoomInBlue}
            size={40}
            primary={false}
            onClick={() => mapRef.current?.zoomIn()}
            style={{ position: 'absolute', right: '8px', bottom: '56px' }}
            classNames="ignore-html2canvas"
          />
          <IconButton
            icon={iconZoomOutBlue}
            size={40}
            primary={false}
            onClick={() => mapRef.current?.zoomOut()}
            style={{ position: 'absolute', right: '8px', bottom: '8px' }}
            classNames="ignore-html2canvas"
          />
        </MinimapPopupImage>
      </div>
    </MinimapPopupDiv>
  );
};
