import { useRef, useState } from 'react';
import { format } from 'date-fns';
import styled from 'styled-components';
import axios from 'axios';

import { useNotifications } from '../../../../../../contexts/notificationProvider';
import { useOnClickOutside } from '../../../../../../hooks/useOnClickOutside';
import { useBuildingContext } from '../../../../../../contexts/buildingContext';
import { useProgressContext } from '../../../../../../contexts/progressContext';

interface ObservationPanelProps {
  jobs: any[];
  item: any;
  setShow: (show: boolean) => void;
  point?: number;
}
export const ObservationPanel = ({ jobs, item, setShow }: ObservationPanelProps) => {
  const { addNotification } = useNotifications();
  const { state: buildingState } = useBuildingContext();
  const { state: progressState } = useProgressContext();
  const [selectedJob, setSelectedJob] = useState<any>();
  const [obsVal, setObsVal] = useState<string>();
  const panelRef = useRef<any>();
  useOnClickOutside(panelRef, () => setShow(false));

  /*
  Save Observation
   */
  const saveObservation = () => {
    if (selectedJob && obsVal) {
      let data = {
        job: parseInt(selectedJob),
        observed_quantity: parseInt(obsVal),
        image: buildingState.imageData.data.id,
      };
      axios
        .post(
          `https://api.nexterarobotics.com/api/project/${buildingState.projectId}/tracker/${progressState.activeTracker}/job/${selectedJob}/observation`,
          data
        )
        .then(r => {
          if (r.status < 400) {
            addNotification('Observation Saved', 'success');
          } else {
            addNotification('Observation Not Saved!', 'error');
          }
        });
    }
  };

  return (
    <ObservationPanelContainer ref={panelRef}>
      <ObservationPanelHeader>Observation Panel</ObservationPanelHeader>
      <ObservationPanelBody>
        <ObservationDetails>
          <p>Item: {item && item.id}</p>
          <p>
            Observation date:{' '}
            {format(new Date(buildingState.imageData.data.taken_on), 'MMM dd, yyy')}
          </p>
        </ObservationDetails>
        <div style={{ marginBottom: '20px' }}>
          <h2>Job</h2>
          <select onChange={e => setSelectedJob(e.target.value)}>
            <option>-</option>
            {jobs.map((job: any) => (
              <option value={job.id}>{job.type.job_type.name}</option>
            ))}
          </select>
        </div>
        <div>
          <h2>Observation value</h2>
          <input type="text" onChange={e => setObsVal(e.target.value)} />
        </div>
        <SaveButton onClick={saveObservation}>Save</SaveButton>
      </ObservationPanelBody>
    </ObservationPanelContainer>
  );
};

const ObservationPanelContainer = styled.div`
  position: absolute;
  bottom: 50px;
  right: 20px;
`;

const ObservationPanelHeader = styled.div`
  background: #073c7a;
  color: #fff;
  font-size: 1.1em;
  padding: 10px;
  border-radius: 4px 4px 0 0;
`;

const ObservationPanelBody = styled.div`
  padding: 1em;
  background: #ffffff;
  border-radius: 0 0 4px 4px;
`;

const ObservationDetails = styled.div`
  font-size: 0.8em;
  color: #666;
  margin-bottom: 10px;
`;

const SaveButton = styled.div`
  cursor: pointer;
  background: #073c7a;
  color: #fff;
  border-radius: 2px;
  padding: 5px 10px;
  text-align: center;
  margin: 10px 0;
`;
