import { LoadingIndicator } from '../../../common/LoadingIndicator';
import { ProgressFloorPlanView } from './ProgressFloorPlanView/ProgressFloorPlanView';
import { TagProvider } from '../../../../contexts/tagContext';
import { ImageViewer } from '../../image_viewer/ImageViewer';
import React, { useCallback, useEffect, useState } from 'react';
import { useBuildingContext } from '../../../../contexts/buildingContext';
import { PointPreview } from './PointPreview/PointPreview';
import { useNavigation } from '../../../../hooks/useNavigation';
import { useProgressContext } from '../../../../contexts/progressContext';
import { Job, listJobsForProjectTrackerFloor } from '../../../../api/jobs';

export const ProgressFloorTracker = () => {
  const { state: buildingState, updateBuilding } = useBuildingContext();
  const { navigateToProgressFloor } = useNavigation();
  const { state: progressState, updateProgress } = useProgressContext();
  const [viewerPosition, setViewerPosition] = useState({ yaw: 0, angle: 0, pitch: 0, hfov: 120 });
  const [jobs, setJobs] = useState<Job[]>([]);
  const [jobsLoading, setJobsLoading] = useState<boolean>(false);

  const {
    floorImagesLoading
  } = buildingState;

  const {
    activeTracker,
    observationDate,
  } = progressState;
  
  const showPointPreview = buildingState.pointId && buildingState.pointData && Object.entries(buildingState.imageData).length !== 0;

  const getJobs = useCallback(async () => {
    if(!activeTracker || !buildingState.floorData){
      return
    }
    setJobsLoading(true);
    listJobsForProjectTrackerFloor(buildingState.projectId, activeTracker, buildingState.floorData.floor_code, observationDate)
    .then(j => {
      setJobs(j);
      setJobsLoading(false);
    });
  }, [activeTracker, buildingState.floorData, buildingState.projectId, observationDate]);

  useEffect(() => {
    getJobs();
  }, [getJobs]);

  useEffect(() => {
    if (showPointPreview) {
      updateProgress({showPoints: true});
    }
  }, [showPointPreview, updateProgress]);

  useEffect(() => {
    if (!buildingState.pointId) {
      updateBuilding({
        imageData: {
          image: null,
          data: null,
        },
        pointData: null,
      });
    }
  }, [buildingState.pointId, updateBuilding]);

  return (
    <>
      {floorImagesLoading && <LoadingIndicator />}
      {buildingState.floorData && buildingState.floorData.imageUrl && (
        <ProgressFloorPlanView
          floorData={buildingState.floorData}
          pointData={buildingState.pointData}
          dateRange={buildingState.dateRange}
          viewerPosition={viewerPosition}
          jobs={jobs}
          jobsLoading={jobsLoading}
        />
      )}
      {showPointPreview && (
        <PointPreview
          viewerPosition={viewerPosition}
          onChangeViewerPosition={setViewerPosition}
          onClose={() => {
            navigateToProgressFloor(buildingState.floorId);
          }}
          observationDate={observationDate}
          onChangeObservationDate={newDate => updateProgress({observationDate: newDate})}
          activeTracker={activeTracker}
        />
      )}
      {buildingState.fullscreen &&
        buildingState.floorData &&
        buildingState.pointData &&
        floorImagesLoading &&
        Object.entries(buildingState.imageData).length !== 0 && (
          <TagProvider>
            <ImageViewer />
          </TagProvider>
        )}
    </>
  );
};
